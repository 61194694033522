var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MPopover',_vm._g(_vm._b({ref:"dropdownPopoverRef",attrs:{"placement":_vm.popoverPlacment,"get-popup-container":_vm.popupContainer,"disabled":_vm.disabled,"transition-name":"slide-up","overlay-class-name":"picker-overlay wide-picker-overlay"},on:{"hide":_vm.handleHide,"show":_vm.handleShow},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._t("trigger",function(){return [(!_vm.multiple)?_c('DropdownTrigger',_vm._b({attrs:{"toggle":_vm.handleToggle,"selected-item":_vm.selectedItem,"allow-clear":_vm.allowClear,"focus-event-brodcast":_vm.focusEventBrodcast,"is-open":_vm.isDropdownOpen,"text-only":_vm.textOnly,"disabled":_vm.disabled,"as-input":_vm.asInput,"size":_vm.size,"input-classes":_vm.inputClasses,"placeholder":_vm.placeholder},on:{"reset":function($event){return _vm.handleChange(undefined)}},scopedSlots:_vm._u([{key:"prefix-text",fn:function(ref){
var item = ref.item;
return [_vm._t("trigger-prefix-text",null,{"item":item})]}},(_vm.fullPath && !_vm.multiple && _vm.value && _vm.displayFullPath)?{key:"bellow-input",fn:function(){return [_c('div',{staticClass:"text-neutral-light md-text-xs",staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.displayFullPath)+" ")])]},proxy:true}:null],null,true)},'DropdownTrigger',_vm.$attrs,false)):_c('MultipleTrigger',_vm._b({attrs:{"allow-clear":_vm.allowClear,"focus-event-brodcast":_vm.focusEventBrodcast,"is-open":_vm.isDropdownOpen,"selected-items":_vm.selectedItem,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"options":_vm.flattenRecursive(_vm.options)},on:{"change":_vm.handleChange}},'MultipleTrigger',_vm.$attrs,false))]},{"show":_vm.handleShow,"hide":_vm.handleHide,"toggle":_vm.handleToggle,"currentItem":_vm.selectedItem,"isOpen":_vm.isDropdownOpen})]},proxy:true}],null,true)},'MPopover',_vm.$attrs,false),_vm.listeners),[_c('div',{staticClass:"flex flex-col h-100 min-h-0"},[_c('div',{ref:"scrollContainer",staticClass:"flex h-100 min-h-0 flex-col"},[(_vm.searchable)?_c('div',{staticClass:"my-2 px-2"},[_c('MInput',{ref:"searchBox",attrs:{"placeholder":_vm.$tc('search')},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('MIcon',{attrs:{"name":"search"}})]},proxy:true}],null,false,4045322684),model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1):_vm._e(),(_vm.multiple && _vm.selectedItem.length)?_c('div',{staticClass:"mb-2 px-2",class:{
          'dropdown-selected-items-container': _vm.selectedItem.length > 4,
        }},[_c('div',{staticClass:"text-primary mb-1"},[_vm._v(" "+_vm._s(_vm.$tc('selected'))+" "+_vm._s(_vm.$tc('item', 2))+" ")]),_c('MultipleTrigger',_vm._b({attrs:{"allow-clear":false,"selected-items":_vm.selectedItem,"disabled":"","as-tag":"","can-remove-selected-items-pill":"","display-all-selected-items-pill":"","options":_vm.flattenRecursive(_vm.options),"as-input":false,"input-classes":_vm.inputClasses},on:{"change":_vm.handleChange}},'MultipleTrigger',_vm.$attrs,false)),_c('MDivider',{staticClass:"mb-0 mt-1"})],1):_vm._e(),_vm._t("before-menu"),(_vm.isLargDataSet && _vm.options.length)?_c('InfiniteTree',{ref:"infinieTreeRef",staticClass:"py-1 px-1 tree-list-div",attrs:{"data":_vm.options,"search-term":_vm.searchTerm,"with-bg":false,"row-height":40,"node-fields":['name'],"value":_vm.selectedItem,"multiple":_vm.multiple,"only-leaf-node-selectable":_vm.onlyLeafNodeSelectable,"hidden-options-keys":_vm.hiddenOptionsKeys,"visible-options-keys":_vm.visibleOptionsKeys},on:{"change":_vm.changeHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_vm._t("item",function(){return [_c('MTooltip',{attrs:{"placement":"topLeft"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',{staticClass:"min-w-0 text-ellipsis"},[_vm._v(_vm._s(item.name))])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])]},{"item":item,"disabled":_vm.onlyLeafNodeSelectable})]}}],null,true)}):_vm._e(),(!_vm.isLargDataSet && _vm.currentOptions.length)?_c('FlotoScrollView',[_c('div',{staticClass:"px-2 py-1 ml-5 tree-list-view"},[_c('TreeList',{attrs:{"options":_vm.currentOptions,"value":_vm.value,"highlight-term":_vm.searchTerm,"multiple":_vm.multiple,"show-no-data":_vm.showNoData,"level-margin":_vm.levelMargin,"only-leaf-node-selectable":_vm.onlyLeafNodeSelectable},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
        var select = ref.select;
return [_vm._t("item",null,{"item":item,"select":select,"disabled":_vm.onlyLeafNodeSelectable})]}}],null,true)})],1)]):_vm._e(),_vm._t("after-menu")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }